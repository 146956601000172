<template>
  <div class="support__page">
    <Breadcrumb
      :breadcrumbs="breadcrumbs"
      mainLabelName="Поддержка"
    ></Breadcrumb>

    <TabViewContainer>
       <EmptyVue type="waiting"></EmptyVue>
    </TabViewContainer>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'

export default {
  components: {
    Breadcrumb,
    TabViewContainer,
    EmptyVue
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Поддержка',
          link: '/support'
        }
      ]
    }
  }
}
</script>

<style>
.support__page {
  background-color: white;
  width: 100%;
  height: 100%;
}
</style>
